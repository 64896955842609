:root {
  --font-size-lg: 2rem;
  --font-size-md: 1.5rem;
  --font-size-sm: 1rem;
  --font-size-ssm: 0.7rem;
  --main-width: 80%;
}

@media (min-width: 480px) {
  :root {
    --font-size-lg: 2.75rem;
    --font-size-md: 2rem;
    --font-size-sm: 1.2rem;
    --font-size-ssm: 0.9rem;
    --main-width: 60%;
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size-lg: 3.5rem;
    --font-size-md: 2.5rem;
    --font-size-sm: 1.5rem;
    --font-size-ssm: 1.0rem;
    --main-width: 40%;
  }
}

.background {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
}

.Footer {
  width: 100%;
  background-color: rgb(17, 17, 17);
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.Wrapper {
  background: url("../../public/images/paper_background.webp");
  /**-webkit-backdrop-filter: blur(10px);**/
  /**backdrop-filter: blur(10px);**/
  left: 50%;
  transform: translate(-50%, 0);
  width: var(--main-width);
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.ContentWrapper {
  flex: 0;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../public/images/background.webp");
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
}

.hr {
  border: 0;
  border-top: 3px dashed rgb(17, 17, 17);
  width: 100%;
  background-color: rgb(0, 0, 0, 0);
}